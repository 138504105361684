// Import Vender css here
import "./assets/fonts/fonts.css"; // Import fonts
import "@splidejs/splide/css/core";

// Custom css here
import "./styles/main.css"; // import tailwind main
import "./styles/layouts/blockgridlayout.css"; // Import
import "../css/index.css";

/**  Import Vendor js here
 * Only import modules for its side effects here
 * @see - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#import_a_module_for_its_side_effects_only
 */
import "flowbite";

/** Import custom scripts here
 * Use dynamic import i.e import() with webpack magic comments whereever possible
 * @see - https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
 * @see - https://webpack.js.org/api/module-methods/#magic-comments
 */
import "./scripts/global/cookie.js";
import "./scripts/modules/salesforceForms.js";

import(/* webpackChunkName: "splide" */ "./scripts/modules/carousel.js");
import(/* webpackChunkName: "accordian" */ "./scripts/modules/accordion.js");
import(/* webpackChunkName: "googleMap" */ "./scripts/modules/google.map.js");
// import(/* webpackChunkName: "navBar" */ "./scripts/modules/navBar.js");

/** Expose onclick functions here
 *
 */
export {
  changeImage,
  gotoLink,
} from /* webpackChunkName: "common" */ "./scripts/modules/common.js";
export { loadGoogleMap } from "./scripts/modules/google.map.js";
export { mobileMenu } from "./scripts/modules/navBar.js";
