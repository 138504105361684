// Feature Slider component

import { elmPosiz } from "../util/util.js";

export function changeImage(e) {
  e.preventDefault();
  const {index, imgId} = e.target.dataset;
  let elem = document.getElementById(imgId);
  let images = elem.querySelectorAll('img');
  images.forEach((item,i)=>{
    if (index == i) {
      item.classList.add('block');
      item.classList.remove('hidden');
     } else {
      item.classList.add('hidden');
      item.classList.remove('block');
     }
  });
}

export default {
  changeImage,
};

stickyNavBar("gridStickyNavBarRow__container");
//#region Boat Model Sticky Nav Component
function stickyNavBar(elemId) {
  let navBar = document.getElementById(elemId);
  if (navBar == null) return;
  let navBarElmPos = new elmPosiz(`#${elemId}`);
  let navPos = navBar.getBoundingClientRect().top;
  window.addEventListener("scroll", (e) => {
    let scrollPos = window.scrollY;
    if (scrollPos > navPos) {
      if (navBarElmPos.top < 20) {
        navBar.classList.remove("relative");
        navBar.classList.add("sticky", "top-0");
      }
    } else {
      if (
        navBar.classList.contains("sticky") ||
        navBar.classList.contains("top-0")
      )
        navBar.classList.remove("sticky", "top-0");
      if (!navBar.classList.contains("relative"))
        navBar.classList.add("relative");
    }
  });
}

export function gotoLink(e){
  e.preventDefault();
  const {target} = e;
  const {href,hash} = target;
  if(href.includes("#") && hash.length>1){
    let offset = 200;
    const targetElem = new elmPosiz(hash);
    const offSetPosition = targetElem.top + window.scrollY - offset;
    window.scrollTo({
      top:offSetPosition,
      behavior:"smooth",
    })
  }

}

//  $(".bmodel-sticky-navbar-link").click(function (e) {
//      e.preventDefault();

//      var target = $(this).attr('href');

//      var elem = this;

//      if (target.includes("#")) {
//          var offSet = 200;

//          var mainElement = new elmPosiz(target);

//          var mainElementPosition = mainElement.top;

//          var offsetPosition = mainElementPosition + window.pageYOffset - offSet;

//          window.scrollTo({
//              top: offsetPosition,
//              behavior: "smooth"
//          });
//      }
//      else {
//          if (elem.target != "_blank") {
//              window.location.href = target;
//          }
//          else {
//              window.open(target, '_blank');
//          }
//      }
//  });

//#endregion
