//#region Google Map json
var mapStyleJson = [
    {
        stylers: [
            {
                color: "#4285ea",
            },
        ],
    },
    {
        elementType: "geometry",
        stylers: [
            {
                color: "#f5f5f5",
            },
        ],
    },
    {
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#7e7d72",
            },
        ],
    },
    {
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#b5af69",
            },
            {
                visibility: "off",
            },
        ],
    },
    {
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#616161",
            },
        ],
    },
    {
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#f5f5f5",
            },
        ],
    },
    {
        featureType: "administrative",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "administrative.country",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#bdbdbd",
            },
        ],
    },
    {
        featureType: "administrative.locality",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.neighborhood",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.province",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "administrative.province",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#ecdc27",
            },
        ],
    },
    {
        featureType: "administrative.province",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#050505",
            },
        ],
    },
    {
        featureType: "landscape",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#757575",
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
            {
                color: "#e5e5e5",
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e",
            },
        ],
    },
    {
        featureType: "road",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [
            {
                color: "#ffffff",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#757575",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
            {
                color: "#dadada",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#616161",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e",
            },
        ],
    },
    {
        featureType: "transit",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
            {
                color: "#e5e5e5",
            },
        ],
    },
    {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "water",
        stylers: [
            {
                color: "#b0b2b5",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                color: "#c9c9c9",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e",
            },
        ],
    },
];

var googleMapOptions = {
    zoom: 4,
    center: { lat: 39.8097343, lng: -98.5556199 },
    restriction: {
        latLngBounds: {
            north: 49.382808,
            south: 24.521208,
            east: -66.945392,
            west: -124.736342,
        },
    },
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false,
    styles: mapStyleJson,
};

//#endRegion

const GOOGLE_API_KEY = "AIzaSyCAJenhvW04vcElA7ZUcdY1bR1hd7beP88";
const googleApiUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&callback=initMap&loading=async`;

const mapElem = document.body.querySelector(
    '[data-content-element-type-alias="gridExperianceCenterMapRow"]'
);

if (mapElem != null) {
    var markerDetails = mapElem.querySelectorAll(
        'input[type="hidden"][data-item-index]'
    );
    if (markerDetails != null) {
        addScript(googleApiUrl);
    }
}

window.initMap = function () {
    if (mapElem != null && Array.from(markerDetails).length > 0)
        loadGoogleMap(mapElem);
};

const pinSvg = (color = "black") =>
    `../../.././src/assets/images/map-pins/${color}-location.svg`;

function addScript(url) {
    //check if script already added
    if (document.head.querySelector(`[src="${url}"]`) != null) return;
    const mapScript = document.createElement("script");
    mapScript.src = url;
    mapScript.async = true;
    document.head.appendChild(mapScript);
}

export function loadGoogleMap(elem) {
    const { mapElementId, pinColor } = elem.querySelector(
        "[data-map-element-id]"
    ).dataset;

    const experianceCenterMap = new google.maps.Map(
        document.getElementById(mapElementId),
        googleMapOptions
    );

    Array.from(markerDetails).forEach((item, index) => {
        // console.log(item.dataset.keys) // longitude, latitude, flModalId, modalTitle modalDescription, imageUrl, buttonText, buttonLink, buttonTarget

        const { longitude, latitude, flModalId, modalTitle } = item.dataset;
        const marker = new google.maps.Marker({
            position: {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
            },
            map: experianceCenterMap,
            icon: pinSvg(pinColor),
            title: modalTitle,
        });


        let modal = new Modal(document.getElementById(flModalId))


        marker.addListener('click', function (e) {
            modal.show();

        })



    });
}
